import { Field } from "react-final-form";
import BaseInput from "./base/BaseInput";
import { useTranslation } from "react-i18next";

export default function TextField({
  label,
  name,
  type,
  hint: rHint,
  onBlur,
  className,
  fullWidth,
}: TextFieldProps) {
  const { t } = useTranslation();
  return (
    <Field {...{ name, type }}>
      {({ input, meta }) => {
        const onChange = (v: string) => input.onChange(v);
        const hint =
          meta?.touched && meta?.error
            ? t(meta?.error, {
                field: t(`inputs.${input.name}.label`).toLowerCase(),
              })
            : rHint;
        return (
          <div {...{ className }}>
            <BaseInput
              {...{
                name: input.name,
                label,
                type,
                hint,
                onBlur,
                onChange,
                fullWidth,
              }}
            />
          </div>
        );
      }}
    </Field>
  );
}

interface TextFieldProps {
  label: string;
  name: string;
  type?: string;
  hint?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onChange?: (value: string) => void;
  className?: string;
  fullWidth?: boolean;
}
