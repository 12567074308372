import { UseMutateFunction } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useVenueId } from '../../hooks/query-params';
import { useVenue } from '../../hooks/venue';
import { CreatedUser, User, UserFields } from '../../types/user';

import { useGoogleProfileId } from '../../hooks/query-params';
import PhoneNumberField from '../form/PhoneNumberField';
import SwitchField from '../form/SwitchField';
import Step from './Step';

export default function ContactStepperRetrieve({
  onNextStep,
  isLoading,
}: ContactStepperRetrieveProps) {
  const { t } = useTranslation();
  const venueId = useVenueId();
  const { venue } = useVenue();
  const googleBusinessProfileId = useGoogleProfileId();
  // const flagEnabled = useFeatureFlagEnabled(PosthogFeatureFlags.OptinSwitch);
  return (
    <Step
      {...{ isLoading }}
      buttonLabel={
        googleBusinessProfileId
          ? t('buttons.common.giveReview')
          : t('buttons.common.join')
      }
      initialValues={{
        [UserFields.VENUE_ID]: venueId,
        [UserFields.SUBSCRIBED]: false,
      }}
      onSubmit={(values) => {
        onNextStep({
          ...values,
          subscribed: values.subscribed ? '1' : '0',
          googleBusinessProfileId,
        });
      }}
      title={
        googleBusinessProfileId
          ? t('stepper.retrieve.title')
          : t('stepper.fidelity.title')
      }
      description={
        googleBusinessProfileId ? (
          <>
            <span>{t('stepper.retrieve.description')}</span>
            <br />
            <span>{t('stepper.retrieve.descriptionBis')}</span>
          </>
        ) : (
          t('stepper.fidelity.description', { venue: venue?.venueName })
        )
      }
    >
      <PhoneNumberField
        name={UserFields.PHONE}
        required={!Boolean(googleBusinessProfileId)}
        label={t('inputs.userMobilePhone.label')}
        hint={t('inputs.userMobilePhone.hint')}
        className='mb-5'
      />

      <SwitchField
        name={UserFields.SUBSCRIBED}
        label={t(`inputs.subscribed.labelBis`, {
          venue: venue?.venueName,
          interpolation: { escapeValue: false },
        })}
        className='mb-5'
      />
    </Step>
  );
}

interface ContactStepperRetrieveProps {
  onNextStep: UseMutateFunction<CreatedUser, unknown, User, unknown>;
  isLoading: boolean;
}
