import { useTranslation } from "react-i18next";
import Typography from "./Typography";
import { CreatedUser } from "../types/user";
import { Player } from "@lottiefiles/react-lottie-player";
import Page from "./Page";
import List from "./List";
import ListItem from "./ListItem";
import CheckIcon from "./icons/Check";
import CrossIcon from "./icons/Cross";
import { formatPhone } from "../utils/format";

export default function VerifiedContact({ user }: VerifiedContactProps) {
  const { t } = useTranslation();

  return (
    <Page>
      {/* Hints */}
      <div>
        <Typography variant="title">
          {t("existingContact.welcome")}
          <span className="text-blue-600 ml-2">
            {user?.user?.userFirstName} !
          </span>
        </Typography>
        <Typography variant="paragraph">
          {t("verifiedContact.description")}
        </Typography>
      </div>

      {/* Form */}
      <div>
        <div className="flex flex-col justify-center  overflow-hidden items-center max-h-[260px]">
          <Player
            autoplay
            loop
            src="/lottie/hello.json"
            style={{ width: "340px" }}
          />
        </div>
      </div>
      {/* Actions */}
      <div>
        <List>
          <ListItem
            icon={
              user?.venueUser.venueUserPhoneVerified ? (
                <CheckIcon color="green" />
              ) : (
                <CrossIcon color="red" />
              )
            }
          >
            {formatPhone(user?.user.userMobilePhone) || "06 46 86 21 58"}
          </ListItem>
          <hr />
          <ListItem
            icon={
              user?.venueUser.venueUserEmailVerified ? (
                <CheckIcon color="green" />
              ) : (
                <CrossIcon color="red" />
              )
            }
          >
            {user?.user.userEmailAddress || "nicolas@sofy.fr"}
          </ListItem>
        </List>
      </div>
    </Page>
  );
}

interface VerifiedContactProps {
  user?: CreatedUser;
}
