export type User = {
  readonly creationVenueId: number;
  readonly userId?: number;
  readonly created_at: Date;

  userFirstName: string;
  userEmailAddress: string;
  userMobilePhone: string;
  googleBusinessProfileId: number;

  userBirthday?: string;
  userPostalCode?: string;
  userGender?: UserGender;
  userHomeCountryCode?: string;
  subscribed?: boolean | string;
  userInterestId?: any;
};

export type VenuesUser = {
  venueId: number;
  userId: number;
  venueUserPhoneVerified: boolean;
  venueUserPhoneSubscribed: boolean;
  venueUserEmailVerified: boolean;
  venueUserEmailSubscribed: boolean;
  user: Partial<User>;
};

export enum UserGender {
  MALE = "m",
  FEMALE = "f",
  UNKNOWN = "o",
}

export enum UserFields {
  ID = "userId",
  VENUE_ID = "creationVenueId",
  FIRST_NAME = "userFirstName",
  LAST_NAME = "userLastName",
  BIRTH_DATE = "userBirthday",
  COUNTRY = "userHomeCountryCode",
  EMAIL = "userEmailAddress",
  PHONE = "userMobilePhone",
  POSTAL_CODE = "userPostalCode",
  GENDER = "userGender",
  OTP = "token",
  CGU = "cgu",
  SUBSCRIBED = "subscribed",
  INTERESTS = "userInterestId",
}

export enum UserState {
  NEW_OPTIN = "new_optin",
  EXISTING_OPTIN = "existing_optin",

  NEW_OPTOUT = "new_optout",
  EXISTING_OPTOUT = "existing_optout",

  NEW_UNFILED = "new_unfilled",
  EXISTING_UNFILED = "existing_unfilled",

  COMPLETED = "completed",
}

export enum UserStorageKeys {
  VERIFICATION_TOKEN = "sofy-user-token",
}

export type CreatedUser = {
  venueUser: VenuesUser;
  user: User;
  userPhoneNumber: any;
  token?: string;
};
