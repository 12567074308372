import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import React from "react";
import Layout from "./components/Layout";
import { useStorage } from "./hooks/storage";
import CompleteProfile from "./pages/CompleteProfile";
import EditInformationsPage from "./pages/EditInformationsPage";
import IndexPage from "./pages/IndexPage";
import Validation, { ValidationTypes } from "./pages/Validation";
import { UserStorageKeys } from "./types/user";

function App() {
  const { set: setToken } = useStorage(UserStorageKeys.VERIFICATION_TOKEN);
  const { search } = window.location;
  // Cannot use useLocation() outside router. Have to do it manualy
  const query = new URLSearchParams(search);
  const userToken = query.get("userToken");

  React.useEffect(() => {
    userToken && setToken(userToken);
  }, [userToken, setToken]);

  return (
    <Layout>
      <Router>
        <Routes>
          {/**
           * Redirect user to complete profile if a token is present.
           * Meaning that the user came from
           */}
          {userToken && (
            <Route
              index
              element={<Navigate to={`/complete-profile${search}`} />}
            />
          )}

          <Route path="/" element={<IndexPage />} />
          <Route path="/edit-informations" element={<EditInformationsPage />} />
          <Route path="/complete-profile" element={<CompleteProfile />} />
          <Route
            path="/verify/sms"
            element={<Validation type={ValidationTypes.PHONE} />}
          />
          <Route
            path="/verify/email"
            element={<Validation type={ValidationTypes.EMAIL} />}
          />
        </Routes>
      </Router>
    </Layout>
  );
}

export default App;
