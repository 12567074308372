import { User, VenuesUser } from "./../types/user";
import { parsePhoneNumber } from "libphonenumber-js/min";
export function formatPhone(phone: string | undefined) {
  return phone ? parsePhoneNumber(`+${phone}`).formatNational() : phone;
}

export function venuesUserToUser(
  venuesUser: Partial<VenuesUser> | undefined
): Partial<User> {
  return {
    ...venuesUser?.user,
  };
}
