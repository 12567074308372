import { useQuery } from "@tanstack/react-query";
import { BudyApi } from "../api/budy";
import { useVenueId } from "./query-params";

export function useBudyItems(venueId: number) {
  const { data } = useQuery(
    ["budyItems"],
    async () => await BudyApi.retrieveBudyItems(venueId)
  );

  return data;
}

export function useBudyShopUrl() {
  const venueId = useVenueId();
  return `${process.env.REACT_APP_BUDY_SHOP_URL}/partners/${venueId}`;
}
