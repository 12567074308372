import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";

import { PosthogEvent } from "./types/posthog";
import { captureEventForVenue } from "./utils/posthog";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

posthog.init(process.env.REACT_APP_POSTHOG_API_KEY || "", {
  api_host: "https://app.posthog.com",
});

/**
 * Set stale time to 1 hour
 */
const RQClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 60 * 60 } },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (!document.referrer) {
  captureEventForVenue(PosthogEvent.FIDELITY_FORM_QR_CODE_SCAN);
}

root.render(
  <React.StrictMode>
    <QueryClientProvider {...{ client: RQClient }}>
      <PostHogProvider {...{ client: posthog }}>
        <App />
      </PostHogProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
