export default function Layout({ children }: LayoutProps) {
  return (
    <main
      id="mainLayout"
      className="xs:w-full md:w-6/12 lg:w-5/12 bg-bg-slate-300 mx-auto h-full h-fill-available-ios"
    >
      {children}
    </main>
  );
}

interface LayoutProps {
  children: React.ReactNode;
}
