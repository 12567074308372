import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { countries as CountryData } from 'country-data';
import IsoCountries from 'i18n-iso-countries';

import parsePhoneNumberFromString from 'libphonenumber-js';
import { UserFields } from '../../../types/user';
import { useInputVariant } from '../variants/base-input';
import BaseAutocomplete from './BaseAutocomplete';
import BaseInput from './BaseInput';

IsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
IsoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

export default function BasePhoneNumber({
  hint,
  name,
  onChange,
  onFocus,
  onBlur,
  error,
  label,
}: BasePhoneNumberProps) {
  const { t } = useTranslation();
  const styles = useInputVariant(Boolean(error) ? 'error' : 'default');

  const findDialingCode = (value: string) => {
    const raw = CountryData.all.find((c) => c.alpha2 === value);
    return raw?.countryCallingCodes[0] || '';
  };

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [dialingCode, setDialingCode] = useState<string>('');

  const countries = useMemo(() => {
    return CountryData.all.map((country) => ({
      label: `${country.emoji || '   '}  ${findDialingCode(country.alpha2)}  ${
        country.name
      }`,
      value: country.alpha2,
      key: country.alpha3,
      icon: country.emoji,
      dialingCode: findDialingCode(country.alpha2),
    }));
  }, []);

  useEffect(() => {
    const handleChange = () => {
      const pn = parsePhoneNumberFromString(`${dialingCode}${phoneNumber}`);
      if (pn) {
        onChange(pn.number.replace('+', ''));
      }
    };
    if (dialingCode && phoneNumber) {
      handleChange();
    }
  }, [dialingCode, phoneNumber, onChange]);

  const handleMobileFocus = () => {
    console.log(`mobile focus (${dialingCode})`);
    if (dialingCode === '') {
      setDialingCode('  ');
      setTimeout(() => setDialingCode(''), 200);
    }
  };

  return (
    <>
      <div className='grid grid-cols-3 gap-4'>
        <div>
          <BaseAutocomplete
            value={dialingCode}
            name={UserFields.COUNTRY}
            label={t('inputs.userHomeCountryCode.label')}
            errored={Boolean(error)}
            options={countries}
            onChange={(dialingCode) =>
              setDialingCode(findDialingCode(dialingCode))
            }
            inputFormat='{{icon}} {{dialingCode}}'
            autoComplete='false'
          />
        </div>

        <div className='col-span-2'>
          <BaseInput
            fullWidth
            name={name}
            errored={Boolean(error)}
            label={label || t('inputs.userMobilePhone.label')}
            onChange={(v) => setPhoneNumber(v)}
            type='tel'
            onFocus={() => {
              handleMobileFocus();
              onFocus?.();
            }}
            onBlur={() => onBlur?.()}
          />
        </div>
      </div>

      <p className={styles?.hint}>{hint}</p>
    </>
  );
}

interface BasePhoneNumberProps {
  label?: string;
  hint?: string;
  name: string;
  onChange: (v: string) => void;
  onBlur?: (event?: React.FocusEvent<HTMLInputElement, Element>) => void;
  onFocus?: (event?: React.FocusEvent<HTMLInputElement, Element>) => void;
  error?: string;
}
