import { Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'react-i18next';

import { UseMutateFunction } from '@tanstack/react-query';
import { useBudyShopUrl } from '../../hooks/budy';
import { User, VenuesUser } from '../../types/user';
import Button from '../Button';
import Page from '../Page';
import Typography from '../Typography';

export default function ContactStepperResume({
  user,
  subscribeUser,
  renderBudyShopButton,
}: ContactStepperResumeProps) {
  const { t } = useTranslation();
  const budyShopUrl = useBudyShopUrl();
  return (
    <Page>
      {/* Hints */}
      <div>
        <Typography variant='title'>
          {t('existingContact.welcome')}
          {/* <span className="text-blue-600 ml-2">{user?.userFirstName} !</span> */}
        </Typography>
        <Typography variant='paragraph'>
          {t('completeContact.resume.description')}
        </Typography>
      </div>

      {/* Form */}
      <div>
        <div className='flex flex-col justify-center  overflow-hidden items-center max-h-[300px]'>
          <Player
            autoplay
            loop
            src={
              user.subscribed
                ? '/lottie/receive-message.json'
                : '/lottie/mobile-shopping.json'
            }
            style={{ width: '340px' }}
          />
        </div>
      </div>
      <Typography variant='paragraph'>
        {user.subscribed
          ? t('completeContact.resume.optinHint')
          : t('completeContact.resume.optoutHint')}
      </Typography>

      {/* Actions */}
      <div>
        {!user.subscribed && (
          <Button onClick={() => subscribeUser()} additionnalClassName='mb-2'>
            {t('buttons.common.receiveExclusiveOffers')}
          </Button>
        )}

        {renderBudyShopButton && (
          <Button
            variant={user.subscribed ? 'primary' : 'secondary'}
            onClick={() => window.open(budyShopUrl)}
          >
            {t('buttons.common.visitWebShop')}
          </Button>
        )}
      </div>
    </Page>
  );
}

interface ContactStepperResumeProps {
  user: Partial<User>;
  subscribeUser: UseMutateFunction<VenuesUser, unknown, void, unknown>;
  renderBudyShopButton: boolean;
}
