import api from "../config/axios";

import { PublicVenue } from "../types/venue";

export const VenuesApi = {
  retrieve: async (id: number): Promise<PublicVenue> =>
    (await api.get(`/v2/venues/${id}`)).data,

  retrieveTimeZones: async (): Promise<any> =>
    (await api.get(`venues/venueTimeZonesList`)).data,
};
