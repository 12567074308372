import React from "react";

export default function ListItem({ children, icon, action }: ListItemProps) {
  return (
    <li className="w-full flex items-center px-4 py-4 border-gray-200 dark:border-gray-600">
      <span>{icon}</span>
      <span className="basis-1/2">{children}</span>
      <span className="basis-1/4">{action}</span>
    </li>
  );
}

interface ListItemProps {
  children: React.ReactNode | string;
  icon?: React.ReactNode;
  action?: React.ReactNode;
}
