import React, { FunctionComponent } from "react";
import { FieldInputProps, FieldMetaState } from "react-final-form";
import { ButtonBounceWrapper } from "../../Button";

export default function BaseRadio({
  label,
  name,
  icon,
  input,
}: BaseRadioProps) {
  return (
    <div className="mr-1 w-full">
      <ButtonBounceWrapper>
        <div className="w-full">
          <label
            htmlFor={`${name}-${label}`}
            className={`transition-all inline-flex items-center p-1.5 w-full  bg-white rounded-lg border cursor-pointer‡ ${
              input.checked
                ? "border-blue-500 border-2 bg-blue-100 text-blue-600"
                : "text-gray-500 border-slate-300"
            }`}
          >
            <input {...input} id={`${name}-${label}`} className="peer hidden" />
            <div className="flex items-center">
              {icon &&
                React.createElement(icon, {
                  color: input.checked ? "#0068FF" : "#aaa",
                })}
              <div className="w-full ml-6">{label}</div>
            </div>
          </label>
        </div>
      </ButtonBounceWrapper>
    </div>
  );
}

interface BaseRadioProps {
  name: string;
  label: string;
  hint?: string;
  icon?: FunctionComponent<{ color: string }>;
  meta: FieldMetaState<any>;
  input: FieldInputProps<any, HTMLElement>;
}
