import { useEffect, useState } from "react";

export function useLayoutWidth() {
  const [layoutWidth, setLayoutWidth] = useState<number | string>("auto");
  useEffect(() => {
    const mainLayout = document.getElementById("mainLayout");
    setLayoutWidth((mainLayout?.clientWidth || 200) - 48 || "100%");
  }, []);

  return layoutWidth;
}
