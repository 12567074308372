import { FunctionComponent } from "react";
import { Field } from "react-final-form";
import BaseRadio from "./base/BaseRadio";

export default function RadioField({
  name,
  label,
  icon,
  value,
  className,
  ...rest
}: RadioFieldProps) {
  return (
    <Field name={name} type="radio" value={value}>
      {({ input, meta }) => {
        return (
          <div {...{ className }}>
            <BaseRadio {...{ name, label, input, meta, icon, ...rest }} />
          </div>
        );
      }}
    </Field>
  );
}

interface RadioFieldProps {
  name: string;
  label: string;
  value?: string;
  icon?: FunctionComponent<{ color: string }>;
  className?: string;
}
