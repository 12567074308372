import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import Typography from "../components/Typography";
import { useVenueId } from "../hooks/query-params";
import { useCachedUser, useVerifyUser } from "../hooks/user";
import Page from "../components/Page";

interface ValidationProps {
  type: ValidationTypes;
}

export enum ValidationTypes {
  PHONE = "phoneNumber",
  EMAIL = "email",
}

export default function Validation({ type }: ValidationProps) {
  const { t } = useTranslation();
  const { isLoading, isSuccess, isError } = useVerifyUser();
  const { cachedUser } = useCachedUser(useVenueId(), isSuccess);

  return (
    <Page>
      {/* Hints */}
      {isLoading && (
        <div>
          <Typography variant="title">{t("verification.loading")}</Typography>

          <Typography variant="paragraph">
            {type === ValidationTypes.PHONE && t("verification.phone")}
            {type === ValidationTypes.EMAIL && t("verification.email")}
          </Typography>
        </div>
      )}
      {isError && (
        <div>
          <Typography variant="title">Oops ...</Typography>
          <Typography variant="paragraph">{t("verification.error")}</Typography>
        </div>
      )}
      {isSuccess && (
        <div>
          <Typography variant="title">
            {t("existingContact.welcome")}
            <span className="text-blue-600 ml-2">
              {cachedUser?.user?.userFirstName} !
            </span>
          </Typography>

          <Typography variant="paragraph">
            {type === ValidationTypes.PHONE && t("verification.phoneSuccess")}
            {type === ValidationTypes.EMAIL && t("verification.emailSuccess")}
          </Typography>
        </div>
      )}

      {/* Form */}
      <div className="flex flex-col justify-center  overflow-hidden items-center">
        {isLoading && (
          <Player
            autoplay
            loop
            src="/lottie/verification-loader.json"
            style={{ width: "360px" }}
          />
        )}
        {isError && (
          <Player
            autoplay
            loop
            src="/lottie/verification-error.json"
            style={{ width: "390px" }}
          />
        )}
        {isSuccess && (
          <>
            <Player
              autoplay
              loop
              src="/lottie/verification-success.json"
              style={{ width: "450px" }}
            />
            {type === ValidationTypes.EMAIL && (
              <Typography variant="paragraph">
                {t("verification.valideYourPhone")}
              </Typography>
            )}
            {type === ValidationTypes.PHONE && (
              <Typography variant="paragraph">
                {t("verification.valideYourEmail")}
              </Typography>
            )}
          </>
        )}
      </div>

      {/* Actions */}
      <div>
        {isError && (
          <Button onClick={() => window.location.reload()}>
            {t("buttons.common.retry")}
          </Button>
        )}
      </div>
    </Page>
  );
}
