import React from "react";

export default function List({ children }: ListProps) {
  return (
    <ul className="w-full text-sm font-medium my-5 text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
      {children}
    </ul>
  );
}

interface ListProps {
  children: React.ReactNode;
}
