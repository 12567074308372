import { ReactElement } from "react";
import { Field } from "react-final-form";
import BaseSwitch from "./base/BaseSwitch";

export default function SwitchField({
  name,
  label,
  customLabel,
  className,
}: SwitchFieldProps) {
  return (
    <Field {...{ name }} type="checkbox">
      {({ input, meta }) => (
        <div {...{ className }}>
          <BaseSwitch {...{ input, meta, label, customLabel }} />
        </div>
      )}
    </Field>
  );
}

interface SwitchFieldProps {
  name: string;
  label: string;
  className?: string;
  customLabel?: ReactElement;
  value?: string | boolean;
}
