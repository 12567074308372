import { useMutation } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UsersApi } from '../api/users';
import ContactStepperComplete from '../components/stepper/ContactStepperComplete';
import ContactStepperInformations from '../components/stepper/ContactStepperInformations';
import ContactStepperInterests from '../components/stepper/ContactStepperInterests';
import ContactStepperResume from '../components/stepper/ContactStepperResume';
import { useBudyItems } from '../hooks/budy';
import { useCachedUser, useUpdateUser } from '../hooks/user';
import { useVenue } from '../hooks/venue';
import { User } from '../types/user';

export default function CompleteProfile() {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState<Partial<User>>({});
  const navigate = useNavigate();
  const { search } = useLocation();

  const { venue, venueId } = useVenue();

  const {
    cachedUser,
    isSuccess: isCachedUserSuccess,
    hasToken,
  } = useCachedUser(venueId);

  // Fill the form on each step changes
  function onNextStep(value: Partial<User>) {
    setUser((prev) => ({
      ...prev,
      ...value,
      creationVenueId: venueId,
    }));
    setStep(step + 1);
  }

  // Update user profile
  const { mutate: updateUser, isSuccess, isLoading } = useUpdateUser(venueId);
  async function updateProfile(values: any) {
    updateUser({
      ...(cachedUser?.user || {}),
      ...user,
      ...values,
      subscribed: user.subscribed ? '1' : '0',
      userInterestId: values.userInterestId?.join() || '',
      fromComplete: true,
    });
  }
  useEffect(() => {
    if (!isLoading && isSuccess) {
      setStep(3);
    }
  }, [isLoading, isSuccess, step]);

  // Subscribe user if not
  const { mutate: subscribeUser } = useMutation(
    async () =>
      await UsersApi.updateSubscriptions(venueId, {
        venueUserPhoneSubscribed: true,
        venueUserEmailSubscribed: true,
      }),
    {
      onSuccess() {
        setUser((prev) => ({
          ...prev,
          subscribed: true,
        }));
      },
    }
  );

  // // If no cached user (using a different device), redirecting to form as new user
  // useEffect(() => {
  //   if ((isCachedUserSuccess && !cachedUser) || !hasToken) {
  //     // navigate(`/${search}`);
  //   }
  // }, [cachedUser, hasToken, navigate, isCachedUserSuccess, search]);

  // Retrieve budy shop items to render web shop button
  const budyItems = useBudyItems(venueId);

  return (
    <AnimatePresence mode='wait'>
      {step === 0 && (
        <ContactStepperComplete key='index' onNextStep={onNextStep} />
      )}
      {step === 1 && (
        <ContactStepperInformations
          key='informations'
          {...{ venue, onNextStep }}
        />
      )}
      {step === 2 && (
        <ContactStepperInterests
          key='interests'
          isLoading={isLoading}
          onNextStep={updateProfile}
          initialValues={user}
        />
      )}
      {step === 3 && (
        <ContactStepperResume
          key='resume'
          {...{
            user,
            subscribeUser,
            renderBudyShopButton: budyItems.length > 0,
          }}
        />
      )}
    </AnimatePresence>
  );
}
