export function useInputVariant(variant: string) {
  const variants = {
    default: {
      container: "relative flex h-[50px] ",
      label:
        "absolute  text-md text-gray-500 duration-300 transform -translate-y-5 scale-75 top-2 z-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 focus:border-2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1",
      input:
        "block px-2.5 py-4 text-md text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer focus:border-2",
      hint: "flex flex-wrap text-gray-400 px-3 pt-1 text-xs text-extralight min-h-[10px] opacity-1",
    },

    error: {
      container: "relative flex h-[50px]",
      label:
        "absolute  text-md text-red-500 duration-300 transform -translate-y-5 scale-75 top-2 z-1 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-red-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1",
      input:
        "block px-2.5 py-4 text-md text-gray-900 bg-transparent rounded-lg border border-red-500 appearance-none focus:outline-none focus:ring-0 focus:border-red-600 peer focus:border-2 border-2",
      hint: "transition-all italic flex flex-wrap text-red-500 px-3 pt-1 text-xs text-extralight min-h-[10px] translate-x-0 opacity-1",
    },
  };
  const res = variants[variant as keyof typeof variants];
  if (!res) {
    console.error("Unknown variant for base input: ", variant);
    return;
  }

  return res;
}
