import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { UsersApi } from "../api/users";
import { User, UserStorageKeys, VenuesUser } from "../types/user";
import {
  useGoogleProfileId,
  useReviewUrl,
  useValidationToken,
} from "./query-params";
import { useStorage } from "./storage";

export function useCachedUser(venueId: number, autoMutate = true) {
  const [isLoaded, setIsLoaded] = useState(false);

  const { get: retriveToken } = useStorage(UserStorageKeys.VERIFICATION_TOKEN);

  const token = retriveToken();

  const {
    mutate: decodeUser,
    data,
    isLoading,
    isSuccess,
  } = useMutation(async () => await UsersApi.retrieve(venueId));
  useEffect(() => {
    setIsLoaded(true);
    if (isLoaded && token && autoMutate) {
      decodeUser();
    }
  }, [isLoaded, decodeUser, autoMutate, token]);

  return { cachedUser: data, isLoading, isSuccess, hasToken: Boolean(token) };
}

export function useUpdateUser(venueId: number) {
  const { set: setToken } = useStorage(UserStorageKeys.VERIFICATION_TOKEN);
  return useMutation(
    async (user: Partial<User>) => await UsersApi.create(venueId, user),
    {
      onSuccess(data) {
        setToken(data.token);
      },
    }
  );
}

export function useCreateUser(venueId: number) {
  const googleBusinessProfileId = useGoogleProfileId();
  const reviewUrl = useReviewUrl();
  const { set: setToken } = useStorage(UserStorageKeys.VERIFICATION_TOKEN);
  return useMutation(
    async (user: User) => await UsersApi.create(venueId, user),
    {
      onSuccess(data) {
        setToken(data.token);
        if (googleBusinessProfileId) {
          window.location.assign(reviewUrl);
        }
      },
    }
  );
}

export function useVerifyUser() {
  const token = useValidationToken();

  const [isLoaded, setIsLoaded] = useState(false);

  const { isLoading, isSuccess, isError, mutate } = useMutation(
    async (token: string) => await UsersApi.verify(token)
  );

  useEffect(() => {
    setIsLoaded(true);
    if (isLoaded) mutate(token || "");
  }, [isLoaded, token, mutate]);

  return { isLoading, isSuccess, isError };
}

export function useCachedUserVerification(cachedUser: VenuesUser | undefined) {
  return Boolean(
    cachedUser?.venueUserPhoneVerified || cachedUser?.venueUserEmailVerified
  );
}
