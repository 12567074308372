import { Field } from "react-final-form";
import BaseAutocomplete from "./base/BaseAutocomplete";

export default function AutocompleteField({
  label,
  name,
  options,
  className,
  fullWidth,
}: AutocompleteProps) {
  return (
    <Field {...{ name }}>
      {({ input, meta }) => {
        return (
          <div {...{ className }}>
            <BaseAutocomplete
              {...{
                label,
                name,
                options,
                onChange: input.onChange,
                value: input.value,
                fullWidth,
              }}
            />
          </div>
        );
      }}
    </Field>
  );
}

interface AutocompleteProps {
  label: string;
  name: string;
  className?: string;
  options: any[] | readonly any[];
  onChange?: (value: string) => void;
  fullWidth?: boolean;
}
