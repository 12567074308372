import { Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'react-i18next';
import { VenuesUser } from '../../types/user';
import Button from '../Button';
import Page from '../Page';
import Typography from '../Typography';

export default function CompleteProfileStep({
  onNextStep,
  venuesUser,
}: CompleteProfileStepProps) {
  const { t } = useTranslation();
  return (
    <Page>
      {/* Hints */}
      <div>
        <Typography variant='title'>{t('existingContact.welcome')}</Typography>
        <Typography variant='paragraph'>
          {t('completeContact.start.description')}
        </Typography>
      </div>

      {/* Form */}
      <div>
        <div className='flex flex-col justify-center overflow-hidden items-center max-h-[260px]'>
          <Player
            autoplay
            loop
            src='/lottie/hello.json'
            style={{ width: '340px' }}
          />
        </div>
        <Typography variant='paragraph'>
          {t('completeContact.start.hint')}
        </Typography>
      </div>

      {/* Actions */}
      <div>
        <Button
          onClick={() => onNextStep({})}
          additionnalClassName={`${
            window.innerHeight > 500 ? 'fixed' : ''
          } w-full bottom-5 mr-12`}
          // style={{ width: "-webkit-fill-available" }}
        >
          {t('buttons.common.completeProfile')}
        </Button>
      </div>
    </Page>
  );
}

interface CompleteProfileStepProps {
  onNextStep: (values: Record<string, any>) => void;
  venuesUser?: VenuesUser | undefined;
}
