import React, { useMemo, useState } from 'react';

import { useLayoutWidth } from '../../../hooks/layout';
import BaseInput from './BaseInput';
// import { useStringTemplate } from "../../../hooks/string-template";
import { useTranslation } from 'react-i18next';

export default function BaseAutocomplete({
  label,
  hint,
  name,
  options,
  showHint = true,
  itemText = 'label',
  onChange,
  fullWidth,
  value,
  className,
  onBlur,
  errored,
  inputFormat,
  ...rest
}: BaseAutocompleteProps) {
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState(false);
  const country = options.find((c) => c.value === value);
  const [search, setSearch] = useState(country?.label || '');
  // const [inputValue, setInputValue] = useState("");
  // const [option, setOption] = useState({});

  // const formattedInputValue = useStringTemplate(inputFormat || "", option);
  // if (formattedInputValue) {
  //   setInputValue(formattedInputValue);
  // }
  const [displayedValue, setDisplayedValue] = useState('');

  const filteredOptions = useMemo(() => {
    const res = options.filter(
      (option) =>
        !search || option.label.toLowerCase().includes(search.toLowerCase())
    );
    return res;
  }, [search, options]);

  const layoutWidth = useLayoutWidth();

  React.useEffect(() => {
    setSearch('');
  }, [value]);

  return (
    <div className='relative'>
      <BaseInput
        label={label}
        errored={errored}
        value={search || displayedValue}
        name={name}
        fullWidth={fullWidth}
        onFocus={() => setIsFocus(true)}
        onBlur={(e) => {
          setTimeout(() => setIsFocus(false), 150);
        }}
        onChange={(v) => {
          setSearch(v);
          if (v) {
            setDisplayedValue('');
          }
        }}
        {...rest}
      />
      {isFocus && (
        <div
          style={{ width: layoutWidth }}
          className={`absolute top-14 z-20 border-slate-200 border bg-white divide-y divide-gray-100 shadow-lg rounded-lg max-h-[300px] overflow-y-auto`}
        >
          <ul className='py-1 text-sm text-gray-700 dark:text-gray-200'>
            {filteredOptions.length ? (
              filteredOptions.map(({ value, label, ...rest }, i) => (
                <li
                  data-testid={`autocomplete-option-${value}`}
                  key={i}
                  onClick={() => {
                    // setOption({ value, label, ...rest });
                    setSearch(null);
                    setDisplayedValue(rest.dialingCode);
                    onChange?.(value);
                    setIsFocus(false);
                  }}
                  className='inline-flex items-center text-left py-2 px-4 w-full text-base text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white'
                >
                  {rest.customLabel || label}
                </li>
              ))
            ) : (
              <div className='inline-flex py-2 px-4 w-full text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white'>
                {t('commons.noData')}
              </div>
            )}
          </ul>
        </div>
      )}
      <input
        className='hidden'
        value={value}
        // onChange={(e) => onChange?.(e.target.value)}
      />
    </div>
  );
}

interface BaseAutocompleteProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
  name: string;
  value: any;
  hint?: string;
  options: any[] | readonly any[];
  itemText?: string;
  className?: string;
  inputFormat?: string;
  showHint?: boolean;
  errored?: boolean;
  fullWidth?: boolean;
  onChange?: (value: any) => void;
  onBlur?: (event?: React.FocusEvent<HTMLInputElement, Element>) => void;
}
