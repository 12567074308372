import { useCallback } from "react";

export function useCapitalize(string: string) {
  const capitalize = useCallback(
    (s: string) => `${s.charAt(0).toUpperCase()}${s.slice(1)}`,
    []
  );

  return capitalize(string);
}
