export default function MaleIcon({ color }: MaleIconProps) {
  return (
    <svg
      className="h-[40px]"
      width="60.7"
      height="73.917"
      version="1.1"
      viewBox="0 0 60.7 73.917"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(-59.85 -62.9)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2.4"
      >
        <line className="st5" x1="72.7" x2="72.7" y1="127.4" y2="135.6" />
        <line className="st5" x1="61.8" x2="61.8" y1="129.9" y2="135.5" />
        <path
          className="st5"
          d="m72.7 129.3h-11.5s-2.9-15.6 16.7-17.9c0 0 2.9 5.8 12.3 5.8"
        />
        <path className="st5" d="m78.7 111.9s1.9-0.9 2.3-4.3" />
        <line className="st5" x1="107.6" x2="107.6" y1="127.3" y2="135.5" />
        <line className="st5" x1="118.6" x2="118.6" y1="129.9" y2="135.5" />
        <path
          className="st5"
          d="m107.6 129.3h11.6s2.9-15.6-16.7-17.9c0 0-2.9 5.8-12.3 5.8"
        />
        <path className="st5" d="m101.7 111.9s-1.9-0.9-2.3-4.3" />
        <path className="st5" d="m80.5 85.6" />
        <path
          className="st5"
          d="m73.5 88.5-0.3-0.2c-1-0.5-2.1 0.1-2.3 1.2-0.2 1.9 0.1 4.8 3.6 6.4 2.4 7.1 8.5 14.9 15.8 14.9s13.4-7.8 15.8-14.9c3.5-1.5 3.8-4.4 3.6-6.4-0.1-1-1.3-1.6-2.3-1.2l-0.3 0.2"
        />
        <path
          className="st5"
          d="m106.8 88.4c-1.3-1.3-2.2-3-2.7-4.7-0.4-1.4-1.1-3.3-2.3-5-7.3 4.4-20.6-3.3-23.1 0-1.3 1.7-2 3.5-2.3 5-0.5 1.8-1.4 3.4-2.7 4.7"
        />
        <path className="st5" d="m99.9 85.6" />
        <path
          className="st5"
          d="m71.9 88.2s-2.9-14.2 5.1-17.8c1-2.3 3-4.1 5.5-5 2-0.7 4.6-1.3 7.7-1.3"
        />
        <path
          className="st5"
          d="m108.5 88.2s2.9-14.2-5.1-17.8c-1-2.3-3-4.1-5.5-5-2-0.7-4.6-1.3-7.7-1.3"
        />
      </g>
    </svg>
  );
}

interface MaleIconProps {
  color: string;
}
