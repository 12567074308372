import { useCallback } from "react";
import { FieldInputProps, FieldMetaState } from "react-final-form";
import { useCapitalize } from "../../../hooks/capitalize";
import { ButtonBounceWrapper } from "../../Button";
import { useTranslation } from "react-i18next";

export default function BaseChip({
  label,
  input,
  value,
  meta,
  onChange,
}: BaseChipProps) {
  const { t } = useTranslation();
  const capitalizeLabel = useCapitalize(t(`interests.${label}`));
  /**
   * Treat chip as a checkbox
   * So value are array and must be managed manually here
   */
  const onSelect = useCallback(() => {
    const values = input.value.includes(value)
      ? input.value.filter((v: any) => v !== value)
      : [...input.value, value];
    onChange(values);
  }, [input, value, onChange]);

  return (
    <ButtonBounceWrapper>
      <button
        onClick={() => onSelect()}
        data-testid={`chip-${label}`}
        className={`rounded-full w-fit  px-4 py-2 cursor-pointer mr-4 mt-4 ${
          input?.value?.includes(value)
            ? "bg-blue-100 text-blue-500"
            : "text-slate-600 bg-slate-100"
        } ${meta.touched && meta.error ? "border-red-500" : ""}`}
      >
        {capitalizeLabel}
      </button>
    </ButtonBounceWrapper>
  );
}

interface BaseChipProps {
  label: string;
  value: string;
  input: FieldInputProps<any>;
  meta: FieldMetaState<any>;
  onChange: (values: any) => void;
}
