import { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "../Button";
import Page from "../Page";
import { Form } from "react-final-form";
import Typography from "../Typography";

export default function Step({
  children,
  onSubmit = (x) => x,
  title,
  description,
  buttonLabel,
  initialValues,
  showActions = true,
  showCgv = false,
  isLoading,
  validate,
  height,
}: StepProps) {
  const { t } = useTranslation();
  return (
    <Page {...{ title, description, height }}>
      <Form
        {...{ initialValues, onSubmit, validate }}
        render={({ handleSubmit }) => (
          <>
            <div className="h-full flex flex-col py-4 sm:py-0 justify-center">
              {children}
            </div>
            <div>
              {showCgv && (
                <Typography variant="hint" dataTestId="cgu">
                  <Trans i18nKey="inputs.cgu.label">
                    En continuant j'accepte les
                    <a
                      href="https://sofy.fr/conditions-generales-sofy/"
                      className="underline text-blue-500"
                    >
                      CGU
                    </a>
                    ainsi que la
                    <a
                      href="https://sofy.fr/charte-de-confidentialite/"
                      className="underline text-blue-500"
                    >
                      politique de confidentialité
                    </a>
                    de OPTIMA92
                  </Trans>
                </Typography>
              )}
              {showActions && (
                <Button
                  additionnalClassName={`w-full bottom-5 mr-12`}
                  onClick={handleSubmit}
                  isLoading={isLoading}
                  dataTestId="step-submit-button"
                >
                  {buttonLabel || t("buttons.common.next")}
                </Button>
              )}
            </div>
          </>
        )}
      />
    </Page>
  );
}

interface StepProps {
  children: ReactNode;
  title: string;
  description?: string | ReactNode;
  buttonLabel?: string;
  showActions?: boolean;
  showCgv?: boolean;
  step?: number;
  isLoading?: boolean;
  onSubmit?: (values: any) => void;
  initialValues?: Record<string, any>;
  validate?: (values: any) => Record<string, any>;
  height?: string;
}
