import { useTranslation } from "react-i18next";

import ChipsField from "../form/ChipsFields";
import Step from "./Step";

import interests from "../../dataset/interests.json";
import { User, UserFields } from "../../types/user";
import { useVenueId } from "../../hooks/query-params";

export default function ContactStepperInterests({
  onNextStep,
  initialValues,
  isLoading,
}: ContactStepperInterestsProps) {
  const { t } = useTranslation();
  const venueId = useVenueId();
  return (
    <Step
      isLoading={isLoading}
      onSubmit={(values) => onNextStep(values)}
      initialValues={
        initialValues
          ? {
              ...initialValues,
              [UserFields.VENUE_ID]: venueId,
            }
          : {}
      }
      title={t("stepper.interests.title")}
      description={t("stepper.interests.description")}
    >
      <ChipsField
        name={UserFields.INTERESTS}
        options={JSON.parse(JSON.stringify(interests))}
      />
    </Step>
  );
}

interface ContactStepperInterestsProps {
  onNextStep: (values: Record<string, any>) => void;
  step?: number;
  initialValues?: Partial<User> | undefined;
  isLoading?: boolean;
}
