import { useQuery } from "@tanstack/react-query";
import { VenuesApi } from "../api/venues";
import { useVenueId } from "./query-params";

export function useVenue() {
  const venueId = useVenueId();

  const {
    data: venue,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    ["venues", venueId],
    async () => await VenuesApi.retrieve(venueId)
  );

  return { venue, isError, isLoading, isSuccess, venueId };
}
