import { motion } from "framer-motion";
import Typography from "./Typography";

export default function Page({
  children,
  title,
  description,
  height,
}: PageProps) {
  return (
    <motion.div
      initial={{ opacity: 0, x: 40 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{
        duration: 0.4,
        damping: 10,
        stiffness: 200,
      }}
      style={{ height: "-webkit-fill-available" }}
    >
      <section
        className={`flex flex-col justify-between h-fill-available-ios ${
          height ? height : window.innerHeight > 600 ? "h-screen" : "h-full"
        }  p-6 md:p-6`}
      >
        {(title || description) && (
          <div className="mb-4">
            {title && <Typography variant="title">{title}</Typography>}
            {description && (
              <Typography variant="paragraph">{description}</Typography>
            )}
          </div>
        )}
        {children}
      </section>
    </motion.div>
  );
}

interface PageProps {
  children: React.ReactNode;
  title?: string;
  description?: string | React.ReactNode;
  height?: string;
}
