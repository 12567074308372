import {
  useGoogleProfileId,
  useReviewUrl,
  useVenueId,
} from "../../hooks/query-params";
import { useCreateUser } from "../../hooks/user";
import ContactStepperRetrieve from "./ContactStepperRetrieve";
import { User } from "../../types/user";
import { useEffect, useState } from "react";
import VerifiedContact from "../VerifiedContact";
import ContactStepperVerification from "./ContactStepperVerification";

export default function ContactStepper() {
  const [step, setStep] = useState(0);

  const venueId = useVenueId();
  const googleBusinessProfileId = useGoogleProfileId();
  const reviewUrl = useReviewUrl();
  const {
    mutate: saveUser,
    isLoading,
    isSuccess,
    data: user,
  } = useCreateUser(venueId);

  useEffect(() => {
    if (isSuccess && step === 0) {
      setStep(step + 1);
    }
  }, [isSuccess, setStep, step, user]);

  /**
   * Create/update user only if a mobile phone is present. Then redirect to the review URL (see onSuccess)
   * Otherwise directly redirect to review URL
   */
  const onNextStep = (values: User) => {
    values.userMobilePhone
      ? saveUser(values)
      : window.location.assign(reviewUrl);
  };

  return (
    <>
      {step === 0 && (
        <ContactStepperRetrieve key="retrieve" {...{ onNextStep, isLoading }} />
      )}

      {step === 1 && user?.venueUser.venueUserPhoneVerified && (
        <VerifiedContact key="verified" {...{ user }} />
      )}
      {step === 1 &&
        !user?.venueUser.venueUserPhoneVerified &&
        !googleBusinessProfileId && (
          <ContactStepperVerification key="verified" {...{ user }} />
        )}
    </>
  );
}
