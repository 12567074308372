import parsePhoneNumberFromString from "libphonenumber-js/mobile";

/**
 * Validates a phone number.
 * @param {String} value
 * @returns {String} i18n key for the error message
 */
export default function isValidPhone(value: string, options: any): string {
  if (!value && options.required) {
    return "inputs.errors.required";
  }
  const phoneNumber = parsePhoneNumberFromString(`+${value}`);

  if (value && (!phoneNumber || !phoneNumber.isValid())) {
    return "inputs.userMobilePhone.errors.invalid";
  }

  return "";
}
