import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ContactStepperRetrieve from "../components/stepper/ContactStepperRetrieve";
import ContactStepperVerification from "../components/stepper/ContactStepperVerification";
import { useVenueId } from "../hooks/query-params";
import { useCreateUser } from "../hooks/user";
import { VenuesUser } from "../types/user";

export default function FormStepper() {
  const [step, setStep] = useState(0);

  const venueId = useVenueId();
  const {
    mutate: onNextStep,
    isSuccess,
    isLoading,
    data: user,
  } = useCreateUser(venueId);

  const { state } = useLocation();

  useEffect(() => {
    if (isSuccess && step === 0) {
      setStep(step + 1);
    }
  }, [isSuccess, setStep, step, user]);

  return (
    <>
      {step === 0 && (
        <ContactStepperRetrieve
          {...{
            onNextStep,
            isLoading,
            initialValues: state as VenuesUser,
          }}
        />
      )}
      {step === 1 && <ContactStepperVerification {...{ user: user }} />}
    </>
  );
}
