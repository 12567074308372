import axios from "axios";

export const BudyApi = {
  retrieveBudyItems: async (venueId: number) =>
    (
      await axios.get(
        `${process.env.REACT_APP_BUDY_API_BASE_URL}/items?zeewifiVenueId=${venueId}`
      )
    ).data,
};
