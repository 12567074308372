import { Player } from "@lottiefiles/react-lottie-player";
import Page from "./Page";

export default function DefaultPageLoader() {
  return (
    <Page>
      <Player
        src="/lottie/loader.json"
        autoplay
        loop
        style={{ width: "100%" }}
      />
    </Page>
  );
}
