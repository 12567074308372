import { useCallback } from "react";
import { Field, useForm } from "react-final-form";
import BaseChip from "./base/BaseChip";

export default function ChipsField({ name, options }: ChipsFieldProps) {
  const { change } = useForm();
  /**
   * Emit change manually for array values
   * And avoid using <Form/>'s onSubmit handler
   */
  const onChange = useCallback((v: any) => change(name, v), [name, change]);

  return (
    <div className="flex flex-wrap">
      {options.map(({ label, value }) => (
        <Field key={value} name={name}>
          {({ input, meta }) => (
            <BaseChip {...{ name, label, input, meta, value, onChange }} />
          )}
        </Field>
      ))}
    </div>
  );
}

interface ChipsFieldProps {
  name: string;
  label?: string;
  options: { [key: string]: string }[];
}
