import DefaultPageLoader from "../components/PageLoader";
import ContactStepper from "../components/stepper/ContactStepper";
import {
  useGoogleProfileId,
  useReviewUrl,
  useVenueId,
} from "../hooks/query-params";
import { useCachedUser } from "../hooks/user";

export default function IndexPage() {
  const venueId = useVenueId();
  const { cachedUser, isLoading } = useCachedUser(venueId);
  const reviewUrl = useReviewUrl();
  const googleBusinessProfileId = useGoogleProfileId();

  /**
   * A user token is present on the device with gbpId in URL mean leave reviews
   * Do not have to create user
   */
  if (cachedUser && googleBusinessProfileId) {
    window.location.assign(reviewUrl);
    return null;
  }

  if (venueId === 0) return <div>error...</div>;

  if (isLoading) return <DefaultPageLoader />;

  return <ContactStepper />;
}
