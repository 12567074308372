import { Field } from "react-final-form";

import "react-phone-input-2/lib/material.css";
import BasePhoneNumber from "./base/BasePhoneNumber";
import validate from "./validations/phone-number";
import { useTranslation } from "react-i18next";

export default function PhoneNumberField({
  name,
  label,
  hint,
  required = false,
  className,
}: PhoneNumberFieldProps) {
  const { t } = useTranslation();
  return (
    <Field {...{ name, validate: (v) => validate(v, { required }) }}>
      {({ input, meta }) => {
        const fHint =
          meta?.touched && meta?.error
            ? t(meta?.error, {
                field: t(`inputs.${input.name}.label`).toLowerCase(),
              })
            : hint;
        const onChange = (v: string) => input.onChange(v);
        return (
          <div {...{ className }}>
            <BasePhoneNumber
              {...{
                label,
                value: input.value,
                name: input.name,
                onChange,
                hint: fHint,
                onBlur: input.onBlur,
                onFocus: input.onFocus,
                error: meta.error && meta.touched,
              }}
            />
          </div>
        );
      }}
    </Field>
  );
}

interface PhoneNumberFieldProps {
  name: string;
  label?: string;
  hint?: string;
  required?: boolean;
  className?: string;
}
