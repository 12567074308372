import { useInputVariant } from '../variants/base-input';

export default function BaseInput({
  label,
  type,
  hint,
  className,
  onFocus,
  onChange,
  onBlur,
  value,
  errored,
  name,
  fullWidth,
  ...rest
}: BaseInputProps) {
  const styles = useInputVariant(errored ? 'error' : 'default');
  return (
    <>
      <div
        className={`${styles?.container} ${
          fullWidth ? 'w-full' : 'min-w-[80px]'
        }`}
      >
        <input
          value={value}
          type={type || 'text'}
          id={`text-field-${name}`}
          className={`${styles?.input} ${
            fullWidth ? 'w-full' : 'min-w-[80px]'
          }`}
          placeholder=' '
          onFocus={(e) => onFocus?.(e)}
          onBlur={(e) => onBlur?.(e)}
          onChange={(e) => onChange?.(e.target.value)}
          {...rest}
        />
        <label htmlFor={`text-field-${name}`} className={`${styles?.label}`}>
          {label}
        </label>
      </div>
      <p className={styles?.hint}>{hint}</p>
    </>
  );
}

interface BaseInputProps {
  label?: string;
  name: string;
  value?: string | number;

  type?: string;
  hint?: string;
  className?: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onChange?: (value: string) => void;
  errored?: boolean;
  fullWidth?: boolean;
}
