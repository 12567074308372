export default function Typography({
  children,
  variant = 'paragraph',
  dataTestId,
}: TypographyProps) {
  const variants: VariantType = {
    title: 'font-Poppins text-3xl font-bold text-slate-800 mb-1 text-primary',
    paragraph: 'font-Poppins text-lg font-light text-slate-500 mb-2',
    hint: 'font-Poppins text-sm font-regular text-slate-500 mb-3',
  };

  return (
    <p
      data-testid={dataTestId}
      className={`${variants[variant as keyof typeof variants]}`}
      // dangerouslySetInnerHTML={{ __html: children as string }}
      {...(typeof children === 'string'
        ? { dangerouslySetInnerHTML: { __html: children } }
        : {})}
    >
      {typeof children !== 'string' ? children : null}
    </p>
  );
}

interface VariantType {
  title: string;
  paragraph: string;
  hint: string;
}

interface TypographyProps {
  children: React.ReactNode;
  variant?: string;
  dataTestId?: string;
}
