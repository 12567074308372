import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useVenueId() {
  const query = useQueryParams();
  return parseInt(query.get("nasid") || "0");
}

export function useValidationToken() {
  const query = useQueryParams();
  return query.get("token");
}

export function useSource() {
  const query = useQueryParams();
  return query.get("source");
}

export function useGoogleProfileId() {
  const query = useQueryParams();

  return parseInt(query.get("googleBusinessProfileId") || "0");
}

export function useUserToken() {
  const query = useQueryParams();
  return query.get("userToken");
}

export function useReviewUrl() {
  const profileId = useGoogleProfileId();
  const venueId = useVenueId();
  if (profileId) {
    return `${process.env.REACT_APP_API_BASE_URL}/v2/venues/${venueId}/googleBusinessProfiles/${profileId}/createReview/redirect`;
  }

  return "";
}
