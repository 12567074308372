import api from "../config/axios";
import { CreatedUser, User, VenuesUser } from "../types/user";

export const UsersApi = {
  create: async (venueId: number, user: Partial<User>): Promise<CreatedUser> =>
    (await api.post(`/v2/venues/${venueId}/users`, user)).data,

  updateSubscriptions: async (
    venueId: number,
    sub: Partial<VenuesUser>
  ): Promise<VenuesUser> =>
    (await api.patch(`/v2/venues/${venueId}/venuesUsers/me/subscriptions`, sub))
      .data,

  verify: async (token: string): Promise<string> =>
    (await api.post(`/v2/users/verifications/${token}`)).data,

  retrieve: async (venueId: number): Promise<VenuesUser> => {
    const venuesUser = (await api.get(`/v2/venues/${venueId}/venuesUsers/me`))
      .data;
    return venuesUser.find((vu: VenuesUser) => vu.venueId === venueId);
  },
};
