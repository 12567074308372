import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CountryData from '../../dataset/countrylist.json';
import { useVenueId } from '../../hooks/query-params';
import { UserFields, UserGender, VenuesUser } from '../../types/user';
import { PublicVenue } from '../../types/venue';
import Typography from '../Typography';
import AutocompleteField from '../form/AutocompleteField';
import RadioField from '../form/RadioField';
import TextField from '../form/TextField';
import FemaleIcon from '../icons/Female';
import MaleIcon from '../icons/Male';
import Step from './Step';

export default function ContactStepperInformations({
  onNextStep,
  initialValues,
}: ContactStepperInformationsProps) {
  const { t } = useTranslation();
  const countries = useMemo(
    () =>
      CountryData.map((country) => ({
        label: country.countryName,
        value: country.countryNameCode,
        key: country.countryNameCode,
      })),
    []
  );

  const venueId = useVenueId();

  return (
    <Step
      height='h-full'
      onSubmit={(values) => onNextStep(values)}
      initialValues={
        initialValues?.user
          ? {
              venueId,
              userMobilePhone: initialValues?.user?.userMobilePhone,
              subscribed:
                initialValues.venueUserEmailSubscribed ||
                initialValues.venueUserPhoneSubscribed,
            }
          : {}
      }
      title={t('stepper.informations.title')}
      description={
        <div className='mt-4'>
          <Typography variant='paragraph'>
            {t('completeContact.start.description')}
          </Typography>
        </div>
      }
      // description={t("stepper.informations.description")}
    >
      <TextField
        name={UserFields.FIRST_NAME}
        label={t('inputs.userFirstName.label')}
        className='mb-5'
        fullWidth
      />
      <TextField
        name={UserFields.LAST_NAME}
        label={t('inputs.userLastName.label')}
        className='mb-5'
        fullWidth
      />
      <div className='flex flex-row mb-10 items-center'>
        <RadioField
          name={UserFields.GENDER}
          value={UserGender.MALE}
          icon={MaleIcon}
          label={t('genders.m')}
          className='w-full'
        />
        <RadioField
          name={UserFields.GENDER}
          value={UserGender.FEMALE}
          icon={FemaleIcon}
          label={t('genders.f')}
          className='w-full'
        />
      </div>
      <TextField
        name={UserFields.BIRTH_DATE}
        label={t('inputs.userBirthday.label')}
        type='date'
        className='mb-5'
        fullWidth
      />
      <TextField
        name={UserFields.EMAIL}
        label={t('inputs.userEmailAddress.label')}
        type='email'
        className='mb-5'
        fullWidth
      />
      <AutocompleteField
        name={UserFields.COUNTRY}
        label={t('inputs.userHomeCountryCode.label')}
        options={countries}
        className='mb-5'
        fullWidth
      />
      <TextField
        name={UserFields.POSTAL_CODE}
        label={t('inputs.userPostalCode.label')}
        type='tel'
        className='mb-5'
        fullWidth
      />
    </Step>
  );
}

interface ContactStepperInformationsProps {
  onNextStep: (value: Record<string, any>) => void;
  step?: number;
  venue: PublicVenue | undefined;
  initialValues?: VenuesUser | undefined;
}
