export default function MaleIcon({ color }: MaleIconProps) {
  return (
    <svg
      className="h-[40px]"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 356.1 201.6"
      version="1.1"
      viewBox="0 0 54.000239 73.695562"
      id="svg28"
      width="54.00024"
      height="73.695564"
    >
      <g
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2.4"
      >
        <path
          d="m 52.80012,72.478471 v -7.9 c 0,-3.6 -1.9,-8 -5,-9.9 -2.7,-1.7 -7.6,-1.8 -9.7,-2.7 0,0 -2.4,11.7 -11.1,11.7 -8.7,0 -11.1,-11.7 -11.1,-11.7 -2.1,0.9 -7,1.1 -9.7,2.7 -3.1,1.9 -5,6.3 -5,9.9 v 7.9"
          id="path6"
        />
        <line
          x1="14.000126"
          x2="14.000126"
          y1="72.478462"
          y2="69.778465"
          id="line8"
        />
        <path
          d="m 6.00012,26.778471 c 0.5,1.5 1.6,3 4,3.9 2.5,8.6 9.2,16.6 17.1,16.6 7.9,0 14.6,-8 17.1,-16.6 4.6,-1.8 4.6,-5.6 4.1,-7.6 -0.2,-0.8 -1,-1.4 -1.8,-1.3 l -1.3,0.1"
          id="path10"
        />
        <path
          d="m 6.00012,26.778471 c -0.4,-1.3 -0.3,-2.7 -0.1,-3.7 0.2,-0.8 1,-1.4 1.8,-1.3 l 1.3,0.1 c 0,0 15,4.9 23.6,-11.7 0,0 1.8,8.5 12.7,11.7"
          id="path12"
        />
        <path
          d="m 27.30012,1.2784713 c 0,0 13.3,-1.90000003 18.9,12.5999997 1,2.5 1.6,5.2 1.8,7.8"
          id="path14"
        />
        <path
          d="m 6.30012,27.578471 c 0.1,1 0.4,2.9 0.6,3.9 0.9,4.4 2.4,10.9 -1.4,14.5 0,0 6.2,1.9 12.8,1.1 h 0.4"
          id="path16"
        />
        <path
          d="m 27.10012,1.2784713 c 0,0 -13.3,-1.90000003 -18.9,12.5999997 -1,2.5 -1.6,5.2 -1.8,7.8"
          id="path18"
        />
        <path
          d="m 19.40012,44.778471 c -0.4,1.9 -0.9,5.4 -2.7,6.7 -0.2,0.2 -0.4,0.3 -0.7,0.5"
          id="path20"
        />
        <line
          x1="40.100117"
          x2="40.100117"
          y1="72.478462"
          y2="69.778465"
          id="line22"
        />
        <path
          d="m 34.70012,44.778471 c 0.4,1.9 0.9,5.4 2.7,6.7 0.2,0.2 0.4,0.3 0.7,0.5"
          id="path24"
        />
        <path
          d="m 48.00012,27.678471 c -0.1,1 -0.4,2.9 -0.6,3.9 -0.9,4.4 -2.4,10.9 1.4,14.5 0,0 -6.2,1.9 -12.8,1.1 h -0.5"
          id="path26"
        />
      </g>
    </svg>
  );
}

interface MaleIconProps {
  color: string;
}
