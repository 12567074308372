/**
 * Based on Sofy API
 */
export enum PosthogEvent {
  FIDELITY_FORM_QR_CODE_SCAN = "fidelity form qr code scan",
}

export enum PosthogFeatureFlags {
  OptinSwitch = "fidelity-optin-switch",
}
