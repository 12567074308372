import Step from "./Step";
import { useTranslation } from "react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";
import { formatPhone } from "../../utils/format";
import { CreatedUser } from "../../types/user";

export default function ContactStepperVerification({
  step,
  user,
}: ContactStepperVerificationProps) {
  const { t } = useTranslation();

  return (
    <Step
      {...{ step }}
      title={t("stepper.verification.title")}
      description={t("stepper.verification.description", {
        email: user?.user?.userEmailAddress,
        phone: formatPhone(user?.user?.userMobilePhone),
      })}
      showActions={false}
    >
      <div className="flex flex-col justify-center items-center  overflow-hidden">
        <Player
          autoplay
          loop
          src="/lottie/ask-to-verify.json"
          style={{ width: "670px" }}
        />
      </div>
    </Step>
  );
}

interface ContactStepperVerificationProps {
  step?: number;
  user?: CreatedUser;
}
