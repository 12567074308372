import { ReactElement, useCallback } from "react";
import { FieldInputProps } from "react-final-form";
import { useTranslation } from "react-i18next";

export default function BaseSwitch({
  label,
  input,
  meta,
  customLabel,
}: BaseSwitchProps) {
  const { t } = useTranslation();

  const onClick = useCallback(() => {
    input.onChange(!input.checked);
  }, [input]);

  return (
    <div className="flex-column">
      <div
        {...{ onClick }}
        data-testid="switch-field"
        className="inline-flex relative items-center cursor-pointer mb-1"
      >
        <input
          {...input}
          id={`switch-field-${input.name}`}
          data-testid={`switch-field-${input.name}`}
          className="sr-only peer"
        />
        <div className="flex">
          <div
            className={`w-full max-w-[45px] grow h-6 transition-all rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${input.checked
                ? "bg-blue-600 after:translate-x-full"
                : meta?.touched && meta?.error
                  ? "bg-red-300"
                  : "bg-gray-200"
              }`}
          ></div>
          <label
            htmlFor={`switch-field-${input.name}`}
            className={`ml-3 -mt-0.5 grow text-sm font-medium  ${meta?.touched && meta?.error ? "text-red-500" : "text-gray-500"
              }`}
          >
            {customLabel || label}
          </label>
        </div>
      </div>
      <p
        className={`transition-all flex flex-wrap italic text-red-500 ml-14 text-xs text-extralight min-h-[15px] ${meta?.touched && meta?.error
            ? "translate-x-0 opacity-1"
            : "-translate-x-6 opacity-0"
          }`}
      >
        {meta?.touched &&
          meta?.error &&
          t(meta.error, {
            field: t(`inputs.${input.name}.label`).toLowerCase(),
          })}
      </p>
    </div>
  );
}

interface BaseSwitchProps {
  id?: string;
  name?: string;
  label: string;
  customLabel?: ReactElement;
  hint?: string;
  value?: string;
  meta?: {
    error?: string;
    touched?: boolean;
  };
  input: FieldInputProps<any, HTMLElement>;
}
